.btn {
  text-decoration: none;
  border: 1px #fff solid;
  display: flex;
  width: fit-content;
  margin: 1vw auto;
  color: #fff;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: auto;
  background: #fff;
  width: max-content;
  height: auto;
  border-radius: 3vw;
  border: 0.1vw solid #3b2774;
}

.btn .text {
  color: #7462a0;
  background: #fff;
  padding: 0rem 0.4rem;
  font-weight: 900;
  font-family: "FbPekan-Black";
}

.btn small {
  margin-top: -0.2rem;
  font-size: 50%;
  display: block;
}

.btn .arrows {
  padding: 0 0.5rem;
}

.btn .arrows.translateX {
  transform: translateX(40%);
}

.btn:disabled {
  opacity: 0.5;
}

.btn.inverted svg path {
  fill: #7462a0;
}

.btn.inverted .arrows {
  background: #fff;
}

.btn.inverted .text {
  background: #7462a0;
  color: #fff;
}

.btn.wide .text {
  width: 90%;
}

.btn.wide {
  width: 85vw;
}

@media (min-width: 600px) {
  .btn.wide .text {
    width: 94%;
  }
  .btn.wide {
    width: 40vw;
  }
  .btn .text {
    color: #7462a0;
    background: #fff;
    padding: 0 10px;
    font-size: 3vh;
  }
  .btn .arrows {
    padding: 0 10px;
    display: flex;
    width: 5vh;
    height: 3.1vh;
    justify-content: center;
    align-items: center;
  }
  .btn .arrows svg {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 600px) {
  .btn {
    font-family: "FbPekan-Black";
    font-family: "FbPekan-Regular";
    font-weight: bold;
    text-decoration: none;
    border: 1px #fff solid;
    border-radius: 0.8rem;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2vw auto;
    color: #fff;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form .btn .text,
  .preview .btn .text,
  .edit-text .btn .text {
    width: max-content;
    padding: 0 15px;
  }
  .btn .arrows {
    padding: 0 8px;
    width: 36px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1vh;
  }
  .btn .arrows svg {
    width: 100%;
    height: 100%;
  }
  .btn .text {
    color: #7462a0;
    background: #fff;
    padding: 0.1rem 0.4rem;
    font-size: 6vw;
  }
}

.btn .ac-twoarr {
  width: 1vw;
  position: relative;
  right: -2.4vw;
}

@media (max-width: 600px) {
  .btn {
    border-radius: 1.8rem;
  }
  .btn .ac-twoarr {
    /* width: 3vw;
    position: relative;
    right: -4.2vw;
    color: #38236e;
    font-size: 4vh; */
    width: 3vw;
    position: relative;
    right: 3.8vw;
    color: #38236e;
    font-size: 4vh;
  }
  .btn .text {
    color: #38236e;
    background: #fff;
    padding: 1.5vw 5vw 1vw 6vw;
    font-size: 6vw;
    font-family: "FbPekan-Regular";
  }
}
