.links {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
}

.ac-share-page .react-share__ShareButton,
.ac-share-page .react-share__ShareButton {
    width: 50px;
    height: 50px;
}

.ac-share-page .react-share__ShareButton svg {
    width: 100%;
    height: 100%
}