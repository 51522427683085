@font-face {
  font-family: "FbPekan-Black";
  src: local("FbPekan-Black"), url(./fonts/FbPekan-Black.ttf) format("truetype");
}

@font-face {
  font-family: "FbPekan-Regular";
  src: local("FbPekan-Regular"),
    url(./fonts/FbPekan-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "FbPekan-Bold";
  src: local("FbPekan-Bold"), url(./fonts/FbPekan-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "FbPekan-Light";
  src: local("FbPekan-Light"), url(./fonts/FbPekan-Light.ttf) format("truetype");
}

@font-face {
  font-family: "FbMarzipan-Bold";
  src: local("FbMarzipan-Bold"),
    url(./fonts/FbMarzipan-Bold.otf) format("opentype");
}

@font-face {
  font-family: "FbMarzipan-Medium";
  src: local("FFbMarzipan-Medium"),
    url(./fonts/FbMarzipan-Medium.otf) format("opentype");
}

@font-face {
  font-family: "FbMarzipan-Regular";
  src: local("FbMarzipan-Regular"),
    url(./fonts/FbMarzipan-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Gobold-Bold";
  src: local("Gobold-Bold"),
    url(./fonts/Gobold-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Gobold-Thin";
  src: local("Gobold-Thin"),
    url(./fonts/Gobold-Thin.otf) format("opentype");
}
@font-face {
  font-family: "Stash-Bold";
  src: local("Stash-Bold"),
  url(./fonts/Stash-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Stash-Light";
  src: local("Stash-Light"),
    url(./fonts/Stash-Light.otf) format("opentype");
}
@font-face {
  font-family: "Stash-Black";
  src: local("Stash-Black"),
    url(./fonts/Stash-Black.otf) format("opentype");
}
@font-face {
  font-family: "Gobold-Regular";
  src: local("Gobold-Regular"),
    url(./fonts/Gobold-Regular.otf) format("opentype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-height: 100vh;
  /* font-family: "FbPekan-Regular"; */
  font-family: "Gobold-Thin";
  font-family: "Stash-Light";
  font-family: "Gobold-Regular";
  color: #fff;
  direction: rtl;
  height: 100vh;
  overflow: hidden;
  background-position: bottom;
}
.ac-alertmessage {
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: auto;
  padding: 20px;
  background-color: #fff;
  color:#38236e;
  font-size: 25px;
  text-align: left;
  direction: ltr;

}
h1 {
  /* font-family: "FbPekan-Black";
  font-family: "FbPekan-Regular"; */
  font-family: "Gobold-Thin";
  font-family: "Stash-Light";
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

h2 {
  /* font-family: "FbPekan-Black";
  
  font-family: "FbPekan-Regular"; */
  font-family: "Gobold-Thin";
      font-family: "Stash-Light";
  font-size: 2.5rem;
  text-align: center;
}

p {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
  width: auto;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.slick-dots {
  bottom: -25px;
  color: #fff;
}

.slick-dots li button:before {
  width: max-content;
  height: max-content;
}

.slick-dots li button:before {
  color: #fff !important;
  opacity: 0.6 !important;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: lightgray;
  /* font-family: "FbPekan-Regular"; */
   font-family: "Gobold-Thin";
    font-family: "Stash-Light";
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: lightgray;
  /* font-family: "FbPekan-Regular"; */
   font-family: "Gobold-Thin";
    font-family: "Stash-Light";
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: lightgray;
  /* font-family: "FbPekan-Regular"; */
   font-family: "Gobold-Thin";
    font-family: "Stash-Light";
}

:-moz-placeholder {
  /* Firefox 18- */
  color: lightgray;
  /* font-family: "FbPekan-Regular"; */
   font-family: "Gobold-Thin";
    font-family: "Stash-Light";
}

.slick-slide img {
  margin: auto;
}

input[type="file"] {
  display: none;
}

body header {
  position: relative;
}

@media (min-width: 600px) {
  .hide-on-desktop {
    display: none;
  }
  .flex-on-desktop {
    display: flex;
  }
  body header {
    margin-bottom: 3rem;
  }
  html {
    font-size: 24px;
  }
  .slick-dots {
    bottom: 3vw !important;
  }
  .slick-dots li button:before {
    color: #fff !important;
    opacity: 0.6 !important;
    font-size: 18px !important;
  }
  h1 {
    font-size: 7vh;
  }
}

@media (max-width: 1000px) {
  h1 {
    font-size: 7vw;
  }
  body {
    background-position: 0 0vw;
    background-size: auto 100%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 9vw;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 1vw;
    height: 1vw;
    margin: 0 3.5vw;
    padding: 0;
    cursor: pointer;
  }
}

div.notUrlValid {
  flex-direction: column;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.shareWithUrlPict {
  width: 40vh;
  height: auto;
  border: 2px solid #fff;
  border-radius: 1rem;
}

.img_share {
  text-align: -webkit-center;
  margin: 4vh auto;
}

.shareWithUrl,
.example_slider {
  position: absolute;
  width: 98vw;
  height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header nav {
  z-index: 9;
}

.aclogo-mila-mob,
.ac-milk-mob {
  display: none;
}

.ac-part-abg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: auto;
}

.ac-abg-mob {
  display: none;
}

@media (max-width: 1000px) {
  .aclogo-mila-mob {
    display: block;
    position: absolute;
    bottom: 3vw;
    width: 20vw;
    right: 3vw;
  }
  .preview .examples {
    display: none;
  }
}

@media (max-width: 600px) {
  .ac-part-abg {
    display: block;
  }
  .ac-milk {
    display: none;
  }
  .ac-abg-deskt {
    display: none;
  }
  .ac-abg-mob {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: auto;
    display: block;
  }
  body {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
