.home {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    transform: translate(-50%, -43%);
}

.home .ac-part {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: url(https://dev.activated.digital/img/2022/bcktext.jpg) no-repeat; */
}

.home .ac-wrapper-parts {
    display: flex;
}

.home .ac-wrapper-parts2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home .ac-wrapper-parts2 .btn {
    margin-top: 6vh;
}

.home .ac-wrapper-parts1 {
    flex-direction: row-reverse;
    width: 100%;
    height: auto;
}

.home .ac-chocolads {
    /* width: 38vw;
    position: relative;
    left: -2vw;
    top: -9vh; */
    left: 2vw;
    position: relative;
    top: -6vh;
    width: 42vw;
    transform: rotate(-0.5deg);
}

.home .ac-ar1 {
    position: absolute;
    left: 23.5vw;
    bottom: 0;
    width: 9%;
}

.home .ac-ar1 img {
    float: left;
}

.home .ac-ar2 {
    position: absolute;
    right: -8.5vw;
    top: -2vw;
    width: 12%;
}

.ac-milk {
    width: 23%;
    position: absolute;
    bottom: 4.8vw;
    left: -5vw;
}

.edit-text .ac-milk {
    bottom: 2.2vh;
    left: 2.2vw;
    width: 19%;
}

.choose-desgin .ac-milk {
    bottom: -33vh;
    left: 1.7vw;
    width: 20%;
}

.preview .ac-milk {
    bottom: -40vh;
    left: 1.7vw;
    width: 20%;
}

.finish .ac-milk {
    bottom: -22vh;
    left: 1.7vw;
    width: 20%;
}

.ac-milk-imgmob {
    display: none;
}

.home .ac-right-part .ac-title-wrapper {
    font-size: 7.5vw;
    position: relative;
    top: -1vh;
    line-height: 0.8;
    right: 1.8vw;
    text-align: right;
    font-weight: 100;
    /* color: #9cd1ed85; */
    color: #9dcfeb;
}

.home .ac-right-part .ac-title-wrapper .ac-fwb {
    font-family: "FbPekan-Black";
    font-weight: 900;
}

.home .ac-right-part .ac-title-wrapper .ac-bo {
    color: #ffffffcf;
    font-size: 7.5vw;
    font-family: "FbPekan-Regular";
}

.home .ac-right-part .ac-title-wrapper .ac-sm {
    font-size: 7.5vw;
    position: relative;
    right: 0;
    font-weight: 100;
    color: #ffffffcf;
    font-family: "FbPekan-Regular";
}

.home .ac-right-part .ac-cube1 {
    position: relative;
    right: 0;
    top: 0;
    width: 24%;
    height: 0;
}

.home .ac-right-part .ac-cube1 img {
    position: relative;
    left: -5vw;
    top: -7vw;
    transform: rotate(0deg);
}

.home .ac-wrapper-parts2.ac-text-wrapper {
    font-size: 1.7vw;
    position: relative;
    bottom: -1.5vw;
    text-align: right;
    right: -1.5vw;
}

.home .ac-wrapper-parts2 .ac-text-wrapper {
    font-size: 1.3vw;
    /* font-size: 1.5vw; */
    direction: ltr;
    margin-top: -6vh;
    text-transform: uppercase;
}

.ac-difcolor {
    /* color: #9cd1ed9e; */
    color: #9cd1ed;
    /* font-family: "FbPekan-Black"; */
    font-weight: 900;
    /* font-family: "Gobold-Thin"; */
    font-family: "Stash-Bold";
    text-transform: unset;
    font-family: "Stash-Black";
}

.home .ac-wrapper-parts2 .ac-difcolor {
    /* font-family: "FbPekan-Bold"; */
    font-family: "Gobold-Bold";
    font-weight: 900;
    font-family: "Stash-Bold";
    font-family: "Stash-Black";
    text-transform: none;
    font-size: 138%;
    
}

.multiply {
    mix-blend-mode: overlay;
}

.choose-desgin,
.edit-text,
.preview,
.finish {
    position: absolute;
    width: 98vw;
    height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.choose-desgin,
.finish {
    /* height: 93vh; */
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.edit-text,
.preview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
}

.home h1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1;
}

.home p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.home .checkbox-wrapper {
    margin-bottom: 0.8rem;
    text-decoration: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.checkbox-wrapper label {
    font-weight: 100;
    font-size: 1.1rem;
    margin-right: 0.2rem;
}

form {
    width: 35%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: right;
    align-content: flex-start;
    align-items: flex-start;
}

.form-group label {
    font-size: 1.2rem;
    visibility: hidden;
    height: 0;
}

input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #3b2774;
    font-family: "FbMarzipan-Bold";
        font-family: "Gobold-Bold";
            font-family: "Stash-Bold";
    font-size: 2vw;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    color: #3b2774;
    font-family: "FbMarzipan-Bold";
        font-family: "Gobold-Bold";
            font-family: "Stash-Bold";
    font-size: 2vw;
}

input:-ms-input-placeholder {
    /* IE 10+ */
    color: #3b2774;
    font-family: "FbMarzipan-Bold";
        font-family: "Gobold-Bold";
            font-family: "Stash-Bold";
    font-size: 2vw;
}

input:-moz-placeholder {
    /* Firefox 18- */
    color: #3b2774;
    font-family: "FbMarzipan-Bold";
        font-family: "Gobold-Bold";
            font-family: "Stash-Bold";
    font-size: 2vw;
}

textarea,
input {
  
    /* border: 0;
    font-size: 2.3vw;
    padding: 0.5rem 1.5rem;
    text-align: right;
    font-family: "FbMarzipan-Bold";
    background: transparent; */
    border: 0;
    font-size: 2vw;
    padding: 0.5rem 1.5rem;
    text-align: left;
    background: transparent;
    width: 100%;
    font-family: "Gobold-Bold";
    font-family: "Stash-Bold";
}

.social-wrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
}

.social-wrapper img {
    cursor: pointer;
    width: 3rem;
    margin: 0 2vh;
}

img.selected {
    border: 3px solid #000;
}


/* .img-wrapper {
    margin: auto;
    width: 49vh;
    height: auto;
    position: relative;
} */

.agree-p {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.crop {
    position: absolute;
    overflow: hidden;
    /* clip-path: circle(7vh at center); */
    border-radius: 50%;
    border: 1px solid #000;
    width: 110px;
    height: 110px;
}

.bracha-text {
    /* position: absolute;
    top: 42.5%;
    width: 20vw;
    left: 39%;
    transform: translate(-50%, -50%);
    font-family: "FbMarzipan-Medium";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.19vw 0.2vw 0 #38236e;
    text-align: right;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300; */
    position: absolute;
    top: 56.5%;
    width: 24.5vw;
    left: 40%;
    transform: translate(-50%, -50%);
    font-family: "Stash-Bold";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.01vw 0.17vw 0 #38236e;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300;
    line-height: 1.2;
}

.bracha-text p {
    /* transform: unset;
    font-size: 4.4vw;
    height: unset;
    position: relative;
    margin-block: 0px;
    text-align: right;
    font-size: 5vw;
    height: auto;
    margin-bottom: 0;
    text-align: right; */
    transform: unset;
    font-size: 4.4vw;
    height: unset;
    position: relative;
    margin-block: 0px;
    text-align: right;
    /* font-size: 3.1vw; */
    font-size: 1.8vw;
    height: auto;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.05vw;
}

.bracha-text p:nth-child(2) {
    /* vertical-align: bottom;
    white-space: nowrap;
    font-size: 9.5vw;
    right: 1.5vw;
    top: -1.2vw;
    height: 5.7vw;
    position: relative;
    margin-block: 0;
    text-align: right;
    line-height: 0.7; */
    vertical-align: bottom;
    white-space: nowrap;
    right: unset;
    /* top: -0.35vw; */
    top: 0.4vw;
    height: 5.7vw;
    position: relative;
    margin-block: 0;
    text-align: center;
    line-height: 0.7;
    /* font-size: 3.1vw; */
    letter-spacing: 0.05vw;
    font-size: 1.8vw;
}

.form .btn .text,
.preview .btn .text,
.edit-text .btn .text {
    width: 100px;
    padding: 0;
}


/* 
.confirmation .btn .text {
    font-size: 1.2rem;
    width: 170px;
    
} */

.confirmation .btn .text {
    font-size: 1.2rem;
    width: max-content;
}

.backdrop {
    background: rgba(121, 102, 166, 0.6);
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.modal svg {
    cursor: pointer;
}

.modal {
    position: relative;
    max-width: 90%;
    width: 500px;
    background: #fff;
    border: 1px #7462a0 solid;
    color: #7462a0;
    border-radius: 23px;
    z-index: 9;
    padding: 2.5rem 1rem 1rem;
}

.modal h2 {
    line-height: 1;
    margin-bottom: 1rem;
}

.modal .btn {
    padding: 0 1rem;
    margin-bottom: 2rem;
}

.modal p:last-child {
    text-align: center;
}

.modal .btn .text {
    background: #7462a0;
    color: #fff;
    font-size: 1.7rem;
}

.form p.error {
    color: #fff;
    font-size: 70%;
    margin-bottom: 0;
    text-align: right;
    position: absolute;
    bottom: -0.9rem;
    font-weight: bold;
}

.slick-slider {
    margin-bottom: 2rem;
}

.finish .img-wrapper,
.preview .img-wrapper {
    overflow: hidden;
    width: 47vw;
}

.finish .img-wrapper {
    top: -5vw;
}

.finish .img-wrapper img.ac-formilka-img,
.preview .img-wrapper img.ac-formilka-img {
    position: relative;
    top: 0;
}

.preview .img-wrapper img.ac-formilka2-img {
    /* position: absolute;
    top: 34%;
    z-index: 1;
    right: 17%;
    width: 23%; */
    position: absolute;
    top: 36%;
    z-index: 1;
    right: 11%;
    width: 23%;
    transform: rotate(5deg);
}

.finish .img-wrapper img.ac-formilka2-img {
    /* position: absolute;
    top: 8.3vw;
    z-index: 1;
    right: 18%;
    width: 21%; */
    position: absolute;
    top: 36%;
    z-index: 1;
    right: 11%;
    width: 23%;
    transform: rotate(5deg);
}

.shareButton h2,
.finish h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
}

.ac-wrapper-smile {
    width: 100%;
    height: 3vh;
    position: relative;
}

.ac-wrapper-smile div {
    width: 3vh;
    height: 3vh;
}

.ac-wrapper-smile div svg {
    width: 100%;
    height: 100%;
}


/* @media (min-width:1100px) and (max-height:650px) {
    .home {
        padding-top: 10vh;
    }
} */

@media (min-width: 1000px) {
    .img-wrapper {
        margin: auto;
        /* width: 274.28px;
        height: 365.71px; */
        width: 45vh;
        height: auto;
        position: relative;
    }
}

@media (min-width: 600px) {
    .confirmation .btn .text {
        width: 250px;
    }
    .preview .flex-on-desktop,
    .finish .flex-on-desktop {
        margin: 1rem auto;
        z-index: 5;
        position: absolute;
        display: flex;
        bottom: -25vh;
        width: 40%;
        justify-content: space-evenly;
        align-items: center;
    }
    .finish .flex-on-desktop {
        flex-direction: column;
        bottom: -38vh;
    }
    .preview .flex-on-desktop button,
    .finish .flex-on-desktop button {
        margin-bottom: 0;
        /* font-family: "FbPekan-Bold"; */
        font-family: "Gobold-Bold";
        height: auto;
        background: #fff;
        width: 45%;
        text-align: center;
        align-items: center;
        justify-content: center;
        /* font-size: 2vw; */
        font-size: 1.6vw;
        color: #3b2774;
        padding: 1vh 0.6vw;
        direction: ltr;
        font-family: "Stash-Bold";
    }
    .finish .flex-on-desktop button {
        width: 63%;
    }
    .preview .flex-on-desktop button.btn .ac-twoarr,
    .finish .flex-on-desktop button.btn .ac-twoarr {
        width: 1.1vw;
        position: relative;
        /* right: 0.2vw; */
        right: -0.5vw;
    }
    .form .btn .text,
    .preview .btn .text,
    .edit-text .btn .text {
        /* width: 200px;
        padding: 0; */
        width: max-content;
        padding: 0 25px;
    }
    .checkbox-wrapper label {
        font-size: 0.8rem;
    }
    .shareButton h2,
    .finish h2 {
        /*font-size: 1.5rem;*/
        font-size: 4vh;
    }
    .social-wrapper {
        width: 15%;
        margin: auto auto 2vh;
    }
    .social-wrapper img {
        cursor: pointer;
        width: 2.5rem;
        width: 7vh;
        margin: unset;
    }
    .social-wrapper button[aria-label="facebook"],
    .social-wrapper button[aria-label="whatsapp"],
    .download_button {
        margin: 0 1vw !important;
        width: 7vh;
        height: 7vh;
    }
    .slick-slider {
        /* width: 552px; */
        width: 60vw;
        /* margin: 0 auto 2rem; */
        margin: 7vh auto 2rem;
        position: fixed;
        left: 50%;
        top: 33%;
        transform: translate(-50%, -50%);
    }
    .choose-desgin .ac-top {
        width: 51vw;
        margin: 0 auto 2rem;
        position: absolute;
        left: 51%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    .choose-desgin .ac-choose-btns {
        position: relative;
        display: flex;
        /* top: 7vw; */
        top: 20vh;
        width: 44%;
        justify-content: space-evenly;
        align-items: center;
    }
    .choose-desgin .ac-hidden {
        visibility: hidden;
    }
    h1 br {
        display: block;
    }
    form {
        width: 30%;
        margin: 0;
        display: flex;
        flex-direction: column;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #000000de;
        border-radius: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(11%, -80%);
        height: 10vw;
        justify-content: center;
    }
    form lavel {
        visibility: hidden;
    }
    .btn p.text {
        color: #3b2774;
        padding: 1.5vh 2.6vw;
        font-size: 1.5vw;
        margin-bottom: 0;
        /* font-family: "FbPekan-Bold"; */
        font-family: "Gobold-Bold";
        height: auto;
        background: #fff;
        font-family: "Stash-Bold";
    }
}

.shareMobile {
    font-size: 31px;
    margin: 0 10px;
}

.social-wrapper .shareMobile img {
    width: 30px;
}

.social-wrapper {
    width: max-content;
    /* margin: auto; */
}

.social-wrapper .shareMobile img {
    cursor: pointer;
    width: 2rem;
    margin-left: 1vh;
}

.shareMobile {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    border: 1px solid #ffffff;
    border-radius: 12px;
    padding: 1vh;
    font-weight: bold;
}

@media (max-width: 1000px) {
    .img-wrapper {
        width: 70vw;
        max-width: 70vw;
    }
    .preview {
        height: auto;
        top: 34%;
    }
    .finish h2,
    .shareButton h2 {
        font-size: 5vw;
    }
    .shareMobile {
        padding: 5px 10px;
    }
    .social-wrapper .shareMobile img {
        width: 3vw;
    }
    .btn p.text {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    /* h1 br {
        display: none;
    } */
    .slick-slider {
        width: 65vw;
    }
    /* .crop {
        clip-path: circle(13vw at center);
    } */
    .social-wrapper .shareMobile img {
        width: 15vw;
        margin: 0 auto 3vw;
    }
    .shareMobile {
        font-size: 5vw;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 0;
    }
    .shareButton h2,
    .finish h2 {
        font-size: 8vw;
    }
    .form .btn .text,
    .preview .btn .text,
    .edit-text .btn .text {
        width: max-content;
        padding: 0 10px;
    }
    .choose-desgin .ac-hidden {
        visibility: hidden;
    }
}

@media (max-width: 330px) {
    .home {
        padding-top: 18vh;
    }
}

.img_global_crop {
    position: absolute;
    /* transform: rotate(-17deg); */
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_global_crop img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 100%;
    max-width: auto;
}

.later-invoice .examples {
    pointer-events: none;
}

.ac-limit {
    visibility: hidden;
    display: none;
}

.button_remove {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.ac-wrapper-close {
    width: 100%;
    height: 3vh;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: start;
    font-size: 2vh;
}

.ac-wrapper-close div {
    width: 3vh;
    height: 3vh;
    margin-left: 1vh;
}

.ac-wrapper-close div svg {
    width: 100%;
    height: 100%;
}

.finish h2 {
    margin-bottom: 0;
}

.ac_new_modal_form form {
    width: 90%;
}

.choose-desgin h1 {
    position: relative;
    z-index: 1;
    top: -14vw;
    font-size: 9vh;
    color: #ffffffcf;
    font-weight: 100;
    direction: ltr;
    /* font-family: "Stash-Light"; */
    font-family: "Stash-Light";
    font-family: "Gobold-Regular";
    line-height: 1.1;

}
.choose-desgin h1 .ac-difcolor{
    font-size: 120%;  
}
.choose-desgin .ac-choose-btns .btn {
    /* color: #3b2774;
    padding: 1vh 0.6vw;
    font-size: 4vh;
    margin-bottom: 0;
    font-family: "FbPekan-Bold";
    height: auto;
    background: #fff;
    width: 45%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw; */
    color: #3b2774;
    padding: 1vh 1.5vw 1vh 1vw;
    font-size: 4vh;
    margin-bottom: 0;
    /* font-family: "FbPekan-Bold"; */
    font-family: "Gobold-Bold";
    font-family: "Stash-Bold";
    height: auto;
    background: #fff;
    width: max-content;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    direction: ltr;
}
.choose-desgin .ac-choose-btns .btn:nth-child(2){
    width:30%
}

.choose-desgin .ac-choose-btns .btn .ac-twoarr {
    /* width: 1vw;
    position: relative;
    right: 0.3vw; */
    width: 1vw;
    position: relative;
    right: -0.5vw;
}

.ac-postcard-txt {
    /* position: absolute;
    top: 44%;
    left: 53.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: 'FbMarzipan-Bold';
    font-size: 4.55vw;
    line-height: 0.7;
    text-shadow: 3px 7px 0px #38236e;
    display: flex;
    flex-direction: column;
    text-align: right; */
    position: absolute;
    top: 44%;
    left: 53.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: "FbMarzipan-Bold";
    font-size: 4.15vw;
    line-height: 1;
    text-shadow: 3px 7px 0px #38236e;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.ac-postcard-txt span {
    display: flex;
    transform: rotate(-9deg);
    line-height: 0.2;
}

.ac-postcard-txt span.ac-postcard-txttop {
    position: relative;
    /* right: -3vw; */
    text-align: right;
    align-self: flex-end;
}

.ac-postcard-txt span.ac-postcard-txtbot {
    /* font-size: 9vw; */
    font-size: 8vw;
}

.slick-initialized .slick-slide {
    position: relative;
}

.slick-initialized .slick-slide .ac-postcard-txtbot {
    position: relative;
    right: 2.5vw;
}

.slick-initialized .slick-slide[data-index="2"] .ac-postcard-txtbot {
    position: relative;
    right: 4.2vw;
}

.slick-initialized .slick-slide[data-index="1"] .ac-postcard-txtbot {
    position: relative;
    right: 2.5vw;
}

.slick-initialized .slick-slide[data-index="3"] .ac-postcard-txtbot {
    position: relative;
    right: -2vw;
}

.slick-initialized .slick-slide[data-index="3"] .ac-postcard-txttop {
    right: -2vw;
}

.slick-initialized .slick-slide[data-index="4"] .ac-postcard-txttop {
    right: -2.3vw;
    top: -0.1vw;
}

.slick-initialized .slick-slide[data-index="4"] .ac-postcard-txtbot {
    right: 0.1vw;
}

.edit-text h1 {
    font-size: 9vh;
    text-align: center;
    position: relative;
    margin-top: -62vh;
    color: #fff;
    font-weight: 100;
    direction: ltr;
    line-height: 1.1;
    font-family: "Gobold-Regular";
}
.edit-text h1 .ac-difcolor{
    font-size: 121%;
}
.edit-text .examples {
    bottom: 4.6vh;
    right: 1.5%;
}

.ac-white {
    color: #fff;
}

.edit-text h1 .ac-withborder {
    color: #6066b2;
    -webkit-text-fill-color: #6066b2;
    -webkit-text-stroke-width: 0.05vw;
    -webkit-text-stroke-color: #fff;
    font-size: 13vh;
}

.edit-text {
    flex-direction: row;
    position: absolute;
    width: 100vw;
    top: 50%;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh !important;
}

.edit-text .ac-chocoladimg-wrapper {
    position: fixed;
    top: 50%;
    width: 35vw;
    height: auto;
    left: 50%;
    transform: translate(-106%, -60%);
}

.edit-text .ac-chocoladimg-wrapper .ac-typedinput {
    /* position: absolute;
    top: 45.5%;
    width: 20vw;
    left: 36%;
    transform: translate(-50%, -50%);
    font-family: "FbMarzipan-Regular";
    font-family: "FbMarzipan-Bold";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.19vw 0.2vw 0 #38236e;
    text-align: right;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300;
    font-family: "FbMarzipan-Medium"; */
    position: absolute;
    top: 59.5%;
    width: 20vw;
    left: 40.8%;
    transform: translate(-50%, -50%);
    font-family: "FbMarzipan-Regular";
    font-family: "FbMarzipan-Bold";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.01vw 0.17vw 0 #38236e;
    text-align: right;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300;
    font-family: "Stash-Bold";
}

.edit-text .ac-chocoladimg-wrapper .ac-typedinput p {
    /* transform: unset;
    font-size: 4.4vw;
    height: unset;
    position: relative;
    margin-block: 0px;
    text-align: right;
    font-size: 4.5vw;
    height: auto;
    margin-bottom: 0;
    text-align: right; */
    transform: unset;
    height: unset;
    position: relative;
    margin-block: 0px;
    text-align: right;
    /* font-size: 2.5vw; */
    font-size: 1.5vw;
    height: auto;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.05vw;
}

.edit-text .ac-chocoladimg-wrapper .ac-typedinput p.ac-line-2 {
    /* vertical-align: bottom;
    white-space: nowrap;
    font-size: 8vw;
    right: 1.5vw;
    top: -1.2vw;
    height: 5.7vw;
    position: relative;
    margin-block: 0;
    text-align: right;
    line-height: 0.7; */
    vertical-align: bottom;
    white-space: nowrap;
    right: unset;
    top: 0.3vw;
    /* top: -0.35vw; */
    height: 5.7vw;
    position: relative;
    margin-block: 0;
    text-align: center;
    line-height: 0.7;
    /* font-size: 2.5vw; */
    font-size: 1.5vw;
    letter-spacing: 0.05vw;
}

.edit-text .ac-chocoladimg-wrapper .ac-formilka-img {
    position: relative;
    top: 0;
}

.edit-text .ac-chocoladimg-wrapper .ac-formilka2-img {
    position: absolute;
    top: 37%;
    z-index: 1;
    right: 17%;
    width: 20%;
    display: none;
}

.edit-text .btn {
    position: fixed;
    color: #3b2774;
    padding: 1.5vh 1.6vw;
    font-size: 2vw;
    margin-bottom: 0;
    /* font-family: "FbPekan-Bold"; */
    font-family: "Gobold-Bold";
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-52%, 315%);
    width: 23%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
    font-family: "Stash-Bold";
}

.edit-text .btn .ac-twoarr {
    width: 1.1vw;
    position: relative;
    /* right: 0.5vw; */
    right: -0.5vw;
}

.preview .img-wrapper,
.finish .img-wrapper {
    height: 100vh;
    position: fixed;
    top: 6vh;
    width: 44vw;
    height: auto;
    left: 50%;
    transform: translate(-50%, -50%);
}
.preview .img-wrapper{
    top: 14vh;
}

.slick-prev:before,
.slick-next:before {
    display: none;
}

.slick-prev,
.slick-next {
    width: auto;
    height: auto;
    z-index: 4;
}

.slick-prev img,
.slick-next img {
    width: 3vw;
}

.preview h1,
.finish h1 {
    /* position: relative;
    z-index: 2;
    top: -31vh;
    font-size: 4vw;
    line-height: 0.9;
    font-weight: 100 !important;
    color: #ffffffcf;
    direction: ltr; */
    position: relative;
    z-index: 2;
    top: -32vh;
    font-size: 3.2vw;
    line-height: 1.2;
    font-weight: 100 !important;
    color: #ffffffcf;
    direction: ltr;
    font-family: "Gobold-Regular";
}

.preview h1 small {
    font-size: 50%;
}

.finisf .shareButton {
    position: absolute;
    bottom: 11vw;
}

.choose-desgin .examples {
    bottom: -30.3vh;
    right: 0%;
}

.preview .examples {
    bottom: -37.6vh;
    right: 0.5%;
}

.edit-text .ac-arrow3-img {
    position: absolute;
    left: -3%;
    bottom: 34%;
    width: 9vw;
    transform: rotate(59deg);
}

.edit-text .ac-ar-img {
    position: fixed;
    top: 33%;
    right: -5%;
    width: 12vw;
    transform: rotate(-15deg);
}

.ac-spiral-img {
    position: fixed;
    width: 16%;
    right: 14%;
}

.finish .img-wrapper {
    position: relative;
    top: -5vh;
    width: 43.9vw;
    /* height: auto; */
    height: 25vw;
    left: 0;
    transform: unset;
}

.finish .flex-on-desktop {
    flex-direction: column;
    bottom: -13vh;
    position: fixed;
}

.finish h1 {
    position: fixed;
    top: -16vh;
    font-size: 4vw;
}

img.download_button:active {
    cursor: wait;
}

.finish .bracha-text p {
    font-size: 3.5vw;
}

.finish .bracha-text p:nth-child(2) {
    font-size: 6.5vw;
}

.finish .bracha-text {
    position: absolute;
    top: 39.5%;
    width: 20vw;
    left: 40%;
    transform: translate(-50%, -50%);
    font-family: "FbMarzipan-Medium";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.19vw 0.2vw 0 #38236e;
    text-align: right;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300;
}

.finish .bracha-text p {
    font-size: 3.5vw;
    transform: unset;
    height: 4.2vw;
    margin-bottom: 0;
}

.finish .bracha-text p:nth-child(2) {
    font-size: 5.5vw;
    position: relative;
    right: 3vw;
    top: -1.5vw;
}

.btn .ac-twoarr img {
    width: 100%;
}

.finish .ac-formilka-img {
    width: 100%;
}

#forUserPreview {
    position: absolute;
    top: -5vh;
    width: 44vw;
    /* height: auto; */
    height: 25vw;
    left: auto;
    transform: unset;
    z-index: 1;
    /* background-size: 226% 218%;
    background-position: 51% 58%; */
    border: 2px dotted #fff;
}


/* #forUserPreview img {
    position: absolute;
} */

#forUserPreview img.ac-formilka2-img {
    /* position: absolute;
    top: 9.3vw;
    z-index: 1;
    right: 18%;
    width: 21%; */
    position: absolute;
    top: 38%;
    z-index: 1;
    right: 11%;
    width: 23%;
    transform: rotate(5deg);
}

#forUserPreview img {
    position: relative;
    top: 1.2vw;
}

.finish .forUserPreviewbracha-text {
    /* position: absolute;
    top: 43.5%;
    width: max-content;
    left: 45%;
    transform: translate(-50%, -50%);
    font-family: "FbMarzipan-Medium";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.19vw 0.2vw 0 #38236e;
    text-align: right;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300; */
    position: absolute;
    top: 63.5%;
    width: 24.5vw;
    left: 40%;
    transform: translate(-50%, -50%);
    font-family: "Stash-Bold";
    font-size: 5vw;
    line-height: 1.2;
    text-shadow: 0.01vw 0.17vw 0 #38236e;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 1;
    font-weight: 300;
    line-height: 1.2;
}

.finish .forUserPreviewbracha-text p {
    transform: unset;
}

.finish .bracha-text p:nth-child(2) {
    top: 0w;
}

.finish .examples {
    bottom: -19.5vh;
    right: 1%;
}

.ac-spiral-imgmob {
    display: none;
}

.home .ac-ar2 .ac-ar2-imgmob,
.home .ac-ar1-imgmob,
.home .ac-chocolads-imgmob,
.home .ac-mobile-line {
    display: none;
}

.ac-show-on-mobile {
    display: none;
}

.ac-MaskGroup5-img {
    display: none;
}

.ac-hidecanvasscreen {
    display: none;
}

@media (max-width: 1000px) and (max-height: 425px) {
    .ac-postcard-txt {
        text-shadow: 0.3vw 0.4vw 0px #38236e;
    }
}

@media (max-width: 1000px) {
    .social-wrapper {
        opacity: 0.5;
        pointer-events: none;
    }
}

@media (max-width: 600px) {
    .ac-hidecanvasscreen {
        position: absolute;
        height: 76vw;
        width: 100%;
        z-index: 1;
        display: block;
        top: 47vw;
        background-size: cover !important;
        background-position: 0 0 !important;
    }
    .ac-show-on-mobile {
        display: block;
    }
    .home .ac-ar1 {
        /* position: absolute;
        left: 0;
        bottom: 37vw;
        
        width: 16%; */
        position: absolute;
    left: 0;
    bottom: 51vw;
    width: 16%;
    }
    .home {
        padding-top: 0vh;
    }
    .home {
        flex-direction: column;
        width: 100vw;
        top: 57%;
        transform: translate(-50%, -50%);
    }
    .home .ac-wrapper-parts2 .ac-text-wrapper {
        font-size: 2vh;
        width: 90%;
        margin-top: 1vh;
        line-height: 1.4;
    }
    .home .ac-wrapper-parts1 {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
    }
    .home .ac-part {
        width: 100%;
    }
    .ac-milk {
        width: 38%;
        position: absolute;
        bottom: 2vw;
        left: 3vw;
    }
    .home .ac-part.ac-left-part {
        position: relative;
        top: 5vh;
    }
    .home .ac-right-part .ac-title-wrapper {
        font-size: 7vh;
        /* font-family: "FbPekan-Bold"; */
        font-family: "Gobold-Bold";
        transform: rotate(0deg);
        position: relative;
        top: 2vh;
        line-height: 0.75;
        text-indent: 18vw;
        right: 0;
        text-shadow: unset;
        text-align: center;
    }
    .slick-initialized .slick-slide[data-index="2"] .ac-postcard-txt .ac-postcard-txtbot {
        top: -0.2vw;
    }
    .home .ac-right-part .ac-title-wrapper .ac-bo {
        font-size: 7vh;
        right: 0;
        margin-right: 0;
        margin-left: auto;
        position: relative;
        right: -9vw;
        font-family: "FbPekan-Regular";
    }
    .home .ac-right-part .ac-title-wrapper .ac-sm {
        font-size: 7vh;
        position: relative;
        right: -12vw;
        top: 3vw;
    }
    .home .ac-right-part .ac-cube1 img {
        position: relative;
        left: -20.5vw;
        top: -13vw;
    }
    .home .btn p.text {
        font-size: 2.5vh;
        font-family: "Gobold-Bold";
        font-family: "Stash-Bold";
    }
    .home .ac-right-part .ac-cube1 {
        position: relative;
        right: -5vw;
        top: 7vw;
        width: 13vh;
        height: 0;
    }
    /* .home .ac-text-wrapper br {
        display: none;
    } */
    .home .ac-right-part .ac-text-wrapper {
        font-size: 4.5vw;
        position: relative;
        bottom: -5.5vh;
        text-align: center;
        width: 93%;
        transform: rotate(2deg);
        margin-top: 3vw;
    }
    .home .ac-mobile-line {
        display: block;
        border-top: 1.2vw dashed #fff;
        width: 8vw;
        margin-top: 0;
        transform: rotate(2deg);
        margin-bottom: 0;
        position: relative;
        top: 7vh;
        visibility: hidden;
    }
    .home .ac-chocolads-img {
        display: none;
    }
    .btn {
        border: 2px solid #38236e !important;
    }
    .home .ac-chocolads {
        width: 64%;
        position: relative;
        left: 1vw;
        top: -2vh;
        /* transform: rotate(11deg); */
        transform: rotate(5deg);
        margin: 6vh auto 1vh;
    }
    .home .ac-chocolads-imgmob {
        display: block;
    }
    .home .ac-ar2-img {
        display: none;
    }
    .home .ac-ar2 {
        position: absolute;
        left: 4vw;
        top: -8vw;
        width: 22%;
    }
    .home .ac-ar2 .ac-ar2-imgmob {
        display: block;
    }
    .home .ac-ar1-img {
        display: none;
    }
    .home .ac-ar1-imgmob {
        display: block;
    }
    /* SCreen 2 */
    .choose-desgin h1 {
        position: relative;
        z-index: 1;
        top: -12vw;
        /* top: -22vw; */
        /* font-size: 19vw; */
        font-size: 12vw;
        text-shadow: unset;
        line-height: 1;
    }
    .choose-desgin .examples {
        display: none;
    }
    .choose-desgin .ac-choose-btns {
        flex-direction: column;
        display: flex;
    }
    .choose-desgin .ac-choose-btns .btn,
    .edit-text .btn,
    .preview .flex-on-desktop .btn {
        border-radius: 1.8rem;
        color: #38236e;
        padding: 2vw 5vw 1.5vw 6vw;
        font-size: 4.5vw;
        width: 100%;
        margin-bottom: 4vw;
        font-family: "Gobold-Bold";
        font-family: "Stash-Bold";
    }
    .preview .flex-on-desktop .btn {
        width: max-content;
        padding: 1vw 12vw;
        font-size: 4.5vw;
        direction: ltr;
    }
    .preview .flex-on-desktop .btn:nth-child(2) {
        padding: 1vw 20vw;
    }
    .edit-text .btn {
        width: 60%;
        padding: 2vw 5vw 1.5vw 6vw;
        top: 125vw;
        font-size: 4.5vw;
        transform: translate(-46%, 50%);
    }
    .finish .bracha-text {
        top: 29%;
    }
    .choose-desgin .ac-choose-btns .btn .ac-twoarr,
    .edit-text .btn .ac-twoarr,
    .preview .flex-on-desktop .btn .ac-twoarr {
        width: 3vw;
        position: relative;
        /* right: 1.8vw; */
        right: -1.2vw;
        font-size: 95%;
    }
    .choose-desgin .ac-choose-btns .btn:nth-child(2) {
        /* font-size: 6vw; */
        width:100%
    }
    .slick-slider {
        width: 100%;
        margin: -15vw auto 0vw;
    }
    .choose-desgin .ac-top {
        position: absolute;
        width: 82vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    .slick-prev img,
    .slick-next img {
        width: 5vw;
    }
    .ac-postcard-txt {
        position: absolute;
        top: 45%;
        left: 52.5%;
        transform: translate(-50%, -50%);
        z-index: 1;
        font-family: "FbMarzipan-Bold";
        font-size: 6.55vw;
        line-height: 1;
        text-shadow: 0.8vw 0.2vw 0px #38236e;
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    .slick-initialized .slick-slide .ac-postcard-txtbot {
        font-size: 13vw;
        right: 3.5vw;
    }
    .edit-text h1 .ac-withborder {
        font-size: 15vw;
    }
    .edit-text h1 {
        position: absolute;
        /* font-size: 6.5vw; */
        font-size: 7.3vw;
        /* margin-top: -132vw; */
        margin-top: -143vw;
        text-shadow: unset;
    }
    .edit-text h1 br {
        display: none
    }
    .edit-text h1 .ac-withborder {
        font-size: 15vw;
        text-shadow: 0 0 black;
        display: inline-block;
        -webkit-text-stroke-width: 0.1vw;
    }
    form {
        width: 75%;
        flex-direction: column;
        position: absolute;
        top: 29%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 0.3rem;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 0px 3px #000000de;
        border-radius: 3vw;
        height: 17vh;
        justify-content: space-around;
        padding-right: 4vw;
    }
    .form-group {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    textarea,
    input {
           /* box-shadow: inset 1px 1px 8px rgb(0 0 0 / 20%); */
    border: 0;
    font-size: 4.5vw;
    padding: 0.5rem 1rem;
    /* border-radius: 0.3rem; */
    /* border-radius: 3vw; */
    direction: ltr;
    text-align: left;
    }
    input::-webkit-input-placeholder {
        font-size: 4.5vw;
            font-family: "Gobold-Bold";
            font-family: "Stash-Bold";
    }
    input::-moz-placeholder {
        font-size: 4.5vw;
            font-family: "Gobold-Bold";
                font-family: "Stash-Bold";
    }
    input:-ms-input-placeholder {
        font-size: 4.5vw;
            font-family: "Gobold-Bold";
                font-family: "Stash-Bold";
    }
    input:-moz-placeholder {
        font-size: 4.5vw;
            font-family: "Gobold-Bold";
                font-family: "Stash-Bold";
    }
    .edit-text .ac-chocoladimg-wrapper {
        position: fixed;
        top: 50%;
        width: 85%;
        height: auto;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .edit-text .examples {
        bottom: 2vw;
        display: none;
    }
    .edit-text .ac-arrow3-img {
        position: fixed;
        left: -5%;
        bottom: 13%;
        width: 28vw;
        transform: rotate(85deg);
    }
    .ac-ar-img {
        display: none;
    }
    .ac-MaskGroup5-img {
        display: block;
        position: fixed;
        top: 0%;
        right: 1%;
        width: 24vw;
    }
    .edit-text .ac-chocoladimg-wrapper .ac-typedinput {
        /* position: absolute;
        top: 37.5%;
        width: 24vw;
        left: 51%;
        transform: translate(-50%, -50%);
        font-family: "FbMarzipan-Medium";
        font-size: 6vw;
        line-height: 0.7;
        text-shadow: 0.6vw 0.6vw 0px #38236e;
        text-align: right;
        display: flex;
        flex-direction: column;
        z-index: 1; */
        display: flex;
        flex-direction: column;
        font-family: "Stash-Bold";
        font-size: 6vw;
        left: 41%;
        line-height: .7;
        position: absolute;
        text-align: right;
        text-shadow: 0.03vw 0.5vw 0 #38236e;
        top: 50.5%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        width: 46vw;
        z-index: 1;
    }
    .edit-text .ac-chocoladimg-wrapper .ac-typedinput p {
        /* transform: rotate(-2deg);
        font-size: 10vw;
        height: unset;
        position: relative;
        margin-block: 0px;
        text-align: right;
        white-space: nowrap; */
        /* font-size: 6vw; */
        font-size: 3.8vw;
        height: auto;
        margin-block: 0;
        position: relative;
        text-align: center;
        white-space: nowrap;
    }
    .edit-text .ac-chocoladimg-wrapper .ac-typedinput p.ac-line-2 {
        /* font-size: 19vw;
        right: 3.3vw;
        top: -2.7vw;
        height: 5.7vw;
        position: relative;
        margin-block: 0px;
        text-align: right;
        line-height: 1;
        vertical-align: bottom; */
        /* font-size: 6vw; */
        font-size: 3.8vw;
        height: 5.7vw;
        line-height: 1;
        margin-block: 0;
        position: relative;
        right: 0;
        text-align: center;
        /* top: 0vw; */
        top: 1.5vw;
        vertical-align: bottom;
    }
    .img-wrapper {
        max-width: 100%;
    }
    .preview h1 br {
        display: block;
    }
    .preview h1,
    .finish h1 {
        position: relative;
        z-index: 2;
        top: -14vw;
        font-size: 10vw;
        line-height: 1.5;
    }
    .preview h1 small {
        font-size: 50%;
        position: relative;
        top: -5vw;
    }
    .preview .img-wrapper {
        height: 100vh;
        position: fixed;
        top: 50%;
        width: 100%;
        height: auto;
        left: 50%;
        transform: translate(-50%, -20%);
    }
    .slick-initialized .slick-slide[data-index="4"] .ac-postcard-txttop {
        right: -4.3vw;
        top: -0.5vw;
    }
    .bracha-text {
        /* position: absolute;
        top: 36.5%;
        width: 24vw;
        left: 49%;
        transform: translate(-50%, -50%);
        font-family: "FbMarzipan-Medium";
        font-size: 6vw;
        line-height: 0.7;
        text-shadow: 0.6vw 0.6vw 0px #38236e;
        text-align: right;
        display: flex;
        flex-direction: column;
        z-index: 1;
        white-space: nowrap; */
        position: absolute;
        top: 49.5%;
        width: 52vw;
        left: 39.5%;
        transform: translate(-50%, -50%);
        font-family: "Stash-Bold";
        font-size: 6vw;
        line-height: 0.7;
        text-shadow: 0.03vw 0.5vw 0 #38236e;
        text-align: right;
        display: flex;
        flex-direction: column;
        z-index: 1;
        white-space: nowrap;
    }
    #forUserPreview .ac-formilka2-img {
        z-index: 9;
    }
    .bracha-text p {
        /* transform: rotate(-2deg);
        font-size: 11vw;
        height: unset;
        position: relative;
        margin-block: 0px;
        text-align: right;
        white-space: nowrap; */
        /* font-size: 7vw; */
        font-size: 4vw;
        height: unset;
        position: relative;
        margin-block: 0px;
        text-align: center;
        white-space: nowrap;
    }
    .bracha-text p:nth-child(2) {
        /* font-size: 20vw;
        right: 4.3vw;
        top: -2.1vw;
        height: 5.7vw;
        position: relative;
        margin-block: 0px;
        text-align: right;
        line-height: 1;
        vertical-align: bottom; */
        /* font-size: 7vw; */
        font-size: 4vw;
        right: 0;
        /* top: -0.1vw; */
        top: 1.4vw;
        height: 5.7vw;
        position: relative;
        margin-block: 0px;
        text-align: center;
        line-height: 1;
        vertical-align: bottom;
    }
    
    .preview .img-wrapper img.ac-formilka2-img {
        /* position: absolute;
        top: 34%;
        z-index: 1;
        right: 18%;
        width: 23%; */
        position: absolute;
        top: 34%;
        z-index: 1;
        right: 11%;
        width: 23%;
    }
    .preview .flex-on-desktop {
        position: relative;
        top: 41vw;
        width: max-content;
    }
    .preview .examples {
        bottom: -63vw;
    }
    .finish h1 {
        position: relative;
        z-index: 2;
        top: 1vw;
        font-size: 18vw;
        line-height: 0.55;
        text-shadow: 0vw 0.5vw 0.6vw #0000009e;
    }
    .finish .btn {
        margin-bottom: 0;
        /* font-family: "FbPekan-Bold"; */
        font-family: "Gobold-Bold";
        height: auto;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 4.5vw;
        color: #3b2774;
        padding: 1vw 9.6vw;
        width: max-content;
        margin-top: 8vw;
        display: flex;
        direction: ltr;
        font-family: "Stash-Bold";
    }
    .finish .img-wrapper img.ac-formilka2-img {
        position: absolute;
        top: 38%;
        z-index: 1;
        right: 11%;
        width: 23%;
    }
    .finish .bracha-text {
        /* position: absolute;
        top: 27%;
        width: 20vw;
        left: 51.5%;
        text-shadow: 0.6vw 0.6vw 0px #38236e;
        white-space: nowrap; */
        position: absolute;
        top: 46%;
        width: 50vw;
        left: 39.5%;
        text-shadow: 0.03vw 0.5vw 0 #38236e;
        white-space: nowrap;
        font-family: "Stash-Bold";
    }
    #forUserPreview {
        position: relative;
        top: -34vw;
        width: 100%;
        height: auto;
        left: auto;
        transform: unset;
        z-index: 9;
        background-size: 300% 300% !important;
        background-position: 0 0 !important;
        border: 0;
    }
    .finish .forUserPreviewbracha-text {
        position: absolute;
        top: -1.5vw;
        width: 20vw;
        left: 53%;
        transform: translate(-50%, -50%);
        font-family: "Stash-Bold";
        font-size: 5vw;
        line-height: 1;
        text-align: right;
        display: flex;
        flex-direction: column;
        z-index: 1;
        white-space: nowrap;
        top: 7vw;
        width: 50vw;
        left: 39.5%;
        text-shadow: 0.03vw 0.5vw 0 #38236e;
        text-align: center;
    }
    .finish .examples {
        bottom: 2vw;
        display: none;
    }
    .ac-spiral-img {
        display: none;
    }
    .ac-spiral-imgmob {
        display: block;
        position: fixed;
        width: 37%;
        right: 7%;
    }
    .finish {
        /* height: 93vh; */
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -5vh;
        transform: unset;
        left: 0;
        width: 100%;
    }
    .finish .img-wrapper {
        width: 100%;
        position: relative;
        top: -8vw;
        left: 0;
        transform: none;
        height: auto;
    }
    #forUserPreview img {
        position: absolute;
        top: -20vw;
    }
    .finish h1 {
        position: relative;
        z-index: 2;
        top: 2vh;
        font-size: 10vw;
        line-height: 0.9;
        text-shadow: unset;
        margin-bottom: 17vw;
    }
    .finish .flex-on-desktop {
        bottom: -2vw;
        z-index: 99;
        position: relative;
    }
    .choose-desgin .slick-slider {
        margin: -8vw auto 8vw;
    }
    #forUserPreview img.ac-formilka2-img {
        position: absolute;
        top: 38%;
        z-index: 1;
        right: 11%;
        width: 23%;
    }
    /* .finish .bracha-text p:nth-child(2) { */
    /* top: 0w; */
    /* top: -3vw; */
    /* top: -5vw; */
    /* top: -28.5vw; */
    /* } */
    .finish .flex-on-desktop .btn .ac-twoarr {
        /* right: 0.8vw; */
        right: -1.2vw;
        font-size: 90%;
    }
}

@media (min-width: 1000px) {
    .edit-text .btn {
        padding: 0.8vh 1.6vw;
    }
}

@media (min-width: 600px) {
    .finish .bracha-text {
        /* position: absolute;
        top: 23%;
        width: 20vw;
        left: 51.5%;
        text-shadow: 0.6vw 0.6vw 0px #38236e;
        white-space: nowrap; */
        position: absolute;
        top: 57.5%;
        width: 24.5vw;
        left: 40%;
        transform: translate(-50%, -50%);
        font-family: "Stash-Bold";
        font-size: 5vw;
        line-height: 1.2;
        text-shadow: 0.01vw 0.17vw 0 #38236e;
        text-align: center;
        display: flex;
        flex-direction: column;
        z-index: 1;
        font-weight: 300;
        line-height: 1.2;
    }
    .finish .bracha-text p {
        font-size: 12vw;
        height: unset;
        position: relative;
        margin-block: 0px;
        text-align: right;
        top: -2vw;
    }
    .finish .bracha-text p:nth-child(2) {
        font-size: 20vw;
        right: 5vw;
        top: -28.5vw;
        height: 1vw;
        position: relative;
        margin-block: 0px;
        text-align: right;
        line-height: 0.5;
        vertical-align: bottom;
    }
}

.ac-loader {
    /* font-size: 20px; */
    text-align: center;
    font-size: 1.4vw;
}

.ac-loader div {
    margin-top: 3px;
}

.ac-loaderimg {
    width: 10%;
}

.finish .shareButton {
    display: none;
}

@media (max-width: 600px) {
    .finish .flex-on-desktop {
        height: 18vh;
    }
    .ac-loader {
        font-size: 4vw;
    }
}


/*First Page */
@media(min-width:600px) {
    .home .ac-right-part .ac-title-wrapper {
        /* font-size: 5.5vw; */
        font-size: 4.7vw;
        position: relative;
        top: -9vh;
        line-height: 1.3;
        right: -1.2vw;
        text-align: left;
        font-weight: 100;
        /* color: #9cd1ed85; */
        color: #9dcfeb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
    .home .ac-right-part .ac-title-wrapper .ac-bo {
        color: #ffffffcf;
        /* font-size: 2.2vw; */
        font-family: "FbPekan-Regular";
        font-family: "Gobold-Thin";
        font-family: "Stash-Light";
        display: block;
        margin-top: 12vh;
        line-height: 1.2;
        text-align: left;
        /* font-family: "Stash-Light";
        font-size: 2.8vw; */
        font-size: 2.2vw;
        font-family: "Gobold-Regular";
        text-transform: uppercase;
    }
    .home .ac-right-part .ac-title-wrapper .ac-fwb {
        font-family: "FbPekan-Black";
        font-family: "Gobold-Bold";
        font-family: "Stash-Bold";
        font-weight: 900;
        display: block;
        margin-top: -11vh;
        font-family: "Stash-Black";
    }
}
@media(max-width:600px) {
    .home .ac-right-part .ac-title-wrapper {
        top: 5vh;
    }
    .home .ac-right-part .ac-title-wrapper .ac-bo {
        /* font-size: 2.5vh; */
        /* font-size: 3.2vh; */
        font-size: 2.2vh;
        right: 0;
        margin-right: 0;
        right: 0vw;
        display: flex;
        text-indent: 1vw;
        line-height: 1.3;
        font-family: "Gobold-Thin";
        font-family: "Stash-Light";
        font-family: "Gobold-Regular";
    }
    .home .ac-right-part .ac-title-wrapper .ac-fwb {
        font-family: "Gobold-Bold";
        font-weight: 900;
        display: block;
        /* font-size: 15vw; */
        /* font-size: 9.5vw; */
        font-size: 9vw;
        text-indent: 1vw;
        margin-top: -6vw;
        margin-bottom: -4vw;
        font-family: "Stash-Bold";
        font-family: "Stash-Black";
    }
    .home .ac-wrapper-parts2 .btn {
        margin-top: 6vh;
        direction: ltr;
    }
    .choose-desgin h1 .ac-difcolor {
        font-size: 93%;
        display: block;
        line-height: 1.4;
    }
}
/*End First Page */