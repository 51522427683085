header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 1rem;
}

.menu-btn {
    fill: #fff;
    width: 40px !important;
    height: 30px !important;
}

header ul {
    z-index: 9;
    position: fixed;
    background: #fff;
    list-style-type: none;
    text-decoration: none;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    -webkit-box-shadow: 2px 2px 15px 1px #000000;
    box-shadow: 2px 2px 15px 1px #000000;
    width: 400px
}

header li {
    /* width: 50vw; */
    width: 100%;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-bottom: 1px solid #7462a0;
}

header li:last-child {
    border-bottom: 0;
}

header nav {
    width: 400px;
}

header a {
    color: #4c1669;
}

.home-link {
    display: block;
}

.ac-logo-desktop {
    width: 149px;
    height: auto;
}

@media(max-width:1000px) {
    .ac-logo-desktop {
        width: 100px;
        height: auto;
    }
    header img {
        /* width: 30%; */
        width: 8vw;
        max-width: 73px;
    }
    header {
        margin: 3%;
    }
}

@media (max-width: 600px) {
    header img {
        /* width: 30%; */
        width: 16vw;
        max-width: 73px;
        height: 12vw;
    }
    .home-link {
        height: 20px;
    }
    header nav,
    header ul {
        width: 300px;
    }
}

@media (min-width: 600px) {
    .home-link {
        height: 28px;
    }
}

ul .home-link svg path {
    fill: #4c1669
}