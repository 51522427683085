.phone-number-confirm {
  margin-top: 5rem;
}
.phone-number-confirm p {
  font-size: 1.4rem;
}

.phone-number-confirm form {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7462a0;
  border-radius: 25px;
  width: 80%;
  margin: auto;
  padding: 2rem 0;
}

.phone-number-confirm input {
  text-align: center;
  border-radius: 25px;
  border: #7462a0 2px solid;
  box-shadow: unset;
  color: #7462a0;
}

.phone-number-confirm .text {
  background: #7462a0;
  color: #fff;
}

.phone-number-confirm ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7462a0;
}
.phone-number-confirm ::-moz-placeholder {
  /* Firefox 19+ */
  color: #7462a0;
}
.phone-number-confirm :-ms-input-placeholder {
  /* IE 10+ */
  color: #7462a0;
}
.phone-number-confirm :-moz-placeholder {
  /* Firefox 18- */
  color: #7462a0;
}
