.examples {
    position: fixed;
    bottom: 13vh;
    left: unset;
    transform: unset;
    width: 13.229vw;
    right: -7%;
    height: auto;
}

.examples img {
    width:80%;
}

@media (max-width: 600px) {
    .examples {
        position: fixed;
        bottom: 3vw;
        left: unset;
        transform: unset;
        width: 25.229vw;
        right: 1%;
        height: auto;
        display: none;
    }
}


/* @media all and (min-width:1100px) and (max-width:650px) {
    .home {
        padding-top: 10vh;
    }
}

@media all and (min-width:675px) and (max-width:1100px) {
    .examples {
        width: 38vh;
    }
}

@media (max-width: 440px) {
    .examples {
        width: 80%;
    }
} */